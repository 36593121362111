<template>
  <div>
    <FilterOverview title="Reiseübersicht" :showFilterInitially="false" @reload="onReload">
      <template #toolbar-left>
        <div
          class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
          style="gap: 12px"
        >
          <b-button-group size="sm" class="d-flex" style="gap: 2px">
            <b-button
              v-for="regionsmanager in regionsmanagers"
              :key="regionsmanager"
              :id="regionsmanager"
              @click="onRegionsmanagerClick"
              :variant="
                regionsmanagersSelectedShortnames.includes(regionsmanager)
                  ? 'primary'
                  : regionsmanagersSelectedShortnames.length === 0 && regionsmanager === 'Alle'
                  ? 'primary'
                  : ''
              "
            >
              {{ regionsmanager }}
            </b-button>
          </b-button-group>
          <b-form-checkbox
            v-model="reisenOhneZukuenftigeTermineAusblenden"
            @change="reisenOhneZukTermineFilter"
            switch
          >
            Reisen ohne zuk. Termine ausblenden
          </b-form-checkbox>
          <b-button size="sm ml-2" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
            Alle Filter zurücksetzen
          </b-button>
        </div>
      </template>
      <template #toolbar-right>
        <b-button
          variant="primary"
          size="sm"
          @click="onAddReiseClick()"
          v-allowedRoles="[Role.ADMIN, Role.PM]"
        >
          <b-icon icon="plus" />
          Reise anlegen
        </b-button>
      </template>
      <template #table="{ tableHeight }">
        <AgGridVue
          :style="{ height: tableHeight + 80 + 'px' }"
          class="ag-theme-alpine m-0 p-0"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowModelType="'clientSide'"
          :rowData="rowData"
          :statusBar="statusBar"
          @grid-ready="onGridReady"
          @rowDoubleClicked="onRowDoubleClicked"
          @filterChanged="onFilterChanged"
          suppressCellFocus
        ></AgGridVue>
      </template>
    </FilterOverview>
    <ReisenModalEdit />
  </div>
</template>

<script>
import { SET_IS_ADDING_REISE, GET_REISEN } from '@/core/produkte/reisen/stores/reisen.module';
import FilterOverview from '@/components/common/filter-overview.vue';
import { mapState, mapActions } from 'vuex';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import regionsmanagerConfig from '@/core/flugverfuegbarkeit/regionsmanager-reisekuerzel.json';
import ReisenModalEdit from '@/components/produkte/reisen/reisen-modal-edit.vue';
import { mapGetters } from 'vuex';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import CellRendererPrioritaet from '@/components/flugverfuegbarkeit/cell-renderer-prioritaet.vue';

export default {
  name: 'ReisenOverviewComponent',
  components: {
    FilterOverview,
    AgGridVue,
    StatusBarComponent,
    ReisenModalEdit,
    agColumnHeader: CustomHeader,
    CellRendererPrioritaet,
  },
  props: {
    tableHeight: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      priorities: [
        { value: null, text: 'Alle' },
        { value: 0, text: 'A' },
        { value: 1, text: 'B' },
        { value: 2, text: 'C' },
        { value: 3, text: 'D' },
      ],
      regionsmanagers: ['Alle', 'NIGO', 'LEBE', 'STGE', 'SYKE', 'NIEX', 'SAKI'],
      // zur filterung der Daten
      regionsmanagersSelected: [],
      // damit die button hervorgehoben werden
      regionsmanagersSelectedShortnames: [],
      columnDefs: null,
      gridApi: null,
      isFilterSupplied: false,
      gridColumnApi: null,
      statusBar: null,
      count: null,
      rowData: null,
      reisenOhneZukuenftigeTermineAusblenden: true,
      defaultColDef: {
        flex: 1,
        filter: true,
        resizable: false,
        floatingFilter: true,
        sortable: true,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
      },
    };
  },
  created() {
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
    this.columnDefs = [
      {
        field: 'reisekuerzel',
        headerName: 'Reise',
        filter: 'agSetColumnFilter',
        sort: 'asc',
        filterParams: {
          values: params => params.success(this.reisen.map(r => r.reisekuerzel)),
        },
        sortable: true,
        minWidth: 150,
        width: 150,
      },
      {
        field: 'region',
        headerName: 'Region',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: params => params.success(this.reisen.map(r => r.region)),
        },
        sortable: false,
        minWidth: 150,
        width: 150,
      },
      {
        field: 'country',
        headerName: 'Land',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: params => params.success(this.reisen.map(r => r.country)),
          cellRenderer: params => (params.value ? params.value.replace(/([A-Z])/g, ' $1') : '-'),
        },
        cellRenderer: params => (params.value ? params.value.replace(/([A-Z])/g, ' $1') : '-'),
        sortable: true,
        minWidth: 130,
        width: 130,
      },
      {
        field: 'prioritaet',
        sortable: true,
        headerName: 'Priorität',
        filter: 'agSetColumnFilter',
        width: 120,
        minWidth: 120,
        filterParams: {
          values: [0, 1, 2, 3],
          debounceMs: 1000,
          cellRenderer: 'CellRendererPrioritaet',
        },
        floatingFilter: true,
        cellRenderer: 'CellRendererPrioritaet',
      },
      {
        field: 'zukuenftigeReisetermine',
        headerName: '# zuk. Reisetermine',
        // 10 -> dezimalsystem 0, wenn der wert null ist
        valueGetter: params => parseInt(params.data['reisetermine@odata.count']),
        sortable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: false,
        minWidth: 150,
        width: 150,
      },
      {
        field: 'action',
        headerName: '',
        filter: false,
        sortable: false,
        cellRenderer: function (params) {
          const linkReisestammdaten = `/#/reisen/${params.data.reisekuerzel}/reisestammdaten`;
          return `
            <div>
              <a href="${linkReisestammdaten}" target="_blank" class="badge badge-secondary action-button" size="sm" >
                <i class="fas fa-pen"></i>
              </a>
            </div>`;
        },
      },
      {
        field: 'regionsmanager',
        headerName: 'Regionsmanager',
        filter: 'agSetColumnFilter',
        hide: true,
      },
    ];
  },

  computed: {
    ...mapGetters(['parsedRoles']),

    ...mapState({
      isInitiallyLoading: state => state.reisen.isInitiallyLoading,
      reisen: state => state.reisen.reisen,
    }),
    regionsmanagerToId() {
      return Object.entries(regionsmanagerConfig).reduce((acc, curr) => {
        acc[curr[1].shortname] = curr[0];
        return acc;
      }, {});
    },
    idToRm() {
      return Object.entries(regionsmanagerConfig).reduce((acc, curr) => {
        acc[curr[0]] = curr[1].shortname;
        return acc;
      }, {});
    },
    isUserAllowedToEdit() {
      return (
        this.parsedRoles.includes('Admin') ||
        this.parsedRoles.includes('Pm') ||
        this.parsedRoles.includes('Yield')
      );
    },
  },
  mounted() {
    this.loadData();
    if (Object.keys(regionsmanagerConfig).includes(this.currentUserId)) {
      this.regionsmanagersSelectedShortnames.push(this.idToRm[this.currentUserId]);
    }
  },
  methods: {
    ...mapActions({
      getReisen: GET_REISEN,
    }),

    checkIfFilterSupplied() {
      this.isFilterSupplied =
        this.gridApi &&
        (Object.keys(this.gridApi?.getFilterModel()).filter(filter => filter !== 'zukuenftigeReisetermine')
          .length > 0 ||
          !this.reisenOhneZukuenftigeTermineAusblenden);
    },
    onFilterChanged() {
      // zählt wie viele Rows im moment angezeigt werden
      this.updateStatusBar(this.gridApi.getDisplayedRowCount());

      this.checkIfFilterSupplied();
    },
    async loadData() {
      await this.getReisen({ isOverview: true });
      const result = this.reisen;
      this.rowData = result;
      this.reisenOhneZukTermineFilter();
      this.count = result.length;
      this.updateStatusBar(this.count);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;

      //gibt ein boolean zurück, wenn die aktuelle Rolle einem der drei brechtigten Rollen entspricht
      const isAllowed = this.isUserAllowedToEdit;

      //setzt die Column automatisch auf visivle oder invisible
      this.gridColumnApi.setColumnVisible('action', isAllowed);
    },
    onReload() {
      this.$store.dispatch(GET_REISEN, { isOverview: true });
      this.gridApi.onFilterChanged();
    },
    onAddReiseClick() {
      this.$store.commit(SET_IS_ADDING_REISE, true);
    },
    onRowDoubleClicked($event) {
      const { data, rowIndex } = $event;
      this.selectedRowID = rowIndex;
      const routeData = this.$router.resolve({ path: '/reisen/' + data.reisekuerzel + '/edit' });
      window.open(routeData.href, '_blank');
    },

    onRegionsmanagerClick($event) {
      // hier holen wir uns die id, welche wir in "regionsmanagerShortname" "umbenennen"
      const { id: regionsmanagerShortname } = $event.target;

      // Wenn 'Alle' ausgewählt wurde, werden alle Filter entfernt
      if (regionsmanagerShortname === 'Alle') {
        this.regionsmanagersSelectedShortnames = [];
        this.regionsmanagersSelected = [];
      } else {
        // regionsmanger wird angeklickt und dann mit der Hilfe von der Methode "regionsmanagerToId" in eine id umgewandelt
        const regionsmanagerId = this.regionsmanagerToId[regionsmanagerShortname];

        // Wenn der Regionsmanager bereits ausgewählt ist, entferne ihn aus beiden Arrays
        if (this.regionsmanagersSelectedShortnames.includes(regionsmanagerShortname)) {
          this.regionsmanagersSelectedShortnames = this.regionsmanagersSelectedShortnames.filter(
            shortname => shortname !== regionsmanagerShortname
          );
          this.regionsmanagersSelected = this.regionsmanagersSelected.filter(id => id !== regionsmanagerId);
        } else {
          // Andernfalls füge ihn in beide Arrays hinzu
          this.regionsmanagersSelectedShortnames.push(regionsmanagerShortname);
          this.regionsmanagersSelected.push(regionsmanagerId);
        }
      }

      // Wenn alle Regionsmanager ausgewählt wurden, leere beide Arrays wieder
      if (this.regionsmanagersSelectedShortnames.length === this.regionsmanagers.length - 1) {
        this.regionsmanagersSelectedShortnames = [];
        this.regionsmanagersSelected = [];
      }

      // Filter für die Regionsmanager -> Ids müssen ein String sein
      const filterValues =
        this.regionsmanagersSelected.length > 0 ? this.regionsmanagersSelected.map(String) : [];

      const regionsmanagerFilter = this.gridApi.getFilterInstance('regionsmanager');
      regionsmanagerFilter.setModel({ values: filterValues });
      this.gridApi.onFilterChanged();
    },

    reisenOhneZukTermineFilter() {
      const filterInstance = this.gridApi.getFilterInstance('zukuenftigeReisetermine');
      const type = this.reisenOhneZukuenftigeTermineAusblenden ? 'greaterThan' : 'greaterThanOrEqual';

      filterInstance.setModel({
        filterType: 'number',
        type,
        filter: 0,
      });

      this.gridApi.onFilterChanged();
    },

    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      this.regionsmanagersSelectedShortnames = [];
      this.reisenOhneZukuenftigeTermineAusblenden = true;
      this.reisenOhneZukTermineFilter();
      this.checkIfFilterSupplied();
    },
  },
};
</script>
<style scoped>
:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
:deep(.ag-cell),
:deep(.ag-header-cell) {
  padding: 0px 0.5rem;
}
</style>
